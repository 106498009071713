@import "../utils/colors";

$subtitle-purple-accent-color: #a089f5;

.fullContainer {
  width: 100%;
}

.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spacingContainer {
  width: 65%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bigText {
  height: 130px;
  font-size: 55px;
  color: $purple-accent-color;
  font-weight: 600;
  text-align: center;
}

.hookCursor {
  height: 130px;
  font-size: 55px;
  color: $purple-accent-color;
  font-weight: 300;
  position: relative;
  bottom: 5px;
}

.cursorInvisible {
  visibility: hidden;
}

.smallText {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: $subtitle-purple-accent-color;
  font-weight: 500;
}

.actionButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 115px;
}

.actionButton {
  padding: 13px 50px;
  background-color: $purple-accent-color;
  border: none;
  border-radius: 100px;
  font-size: 20px;
  font-weight: 500;
  color: $background-color;
}

.actionButton:hover {
  cursor: pointer;
  background-color: #6845db;
}

.spacer {
  height: 300px;
}

.mobileOnly {
  display: none;
}

@media only screen and (max-width: 1100px) {
  .desktopOnly {
    display: none;
  }

  .mobileOnly {
    display: block;
  }

  .ribbonContainer {
    width: 80%;
  }

  .privacyRibbonContainer {
    justify-content: start;
    padding: 30px;
  }

  .spacingContainer {
    width: 90%;
  }

  .privacyPolicyContainer {
    padding: 30px 0px;
  }

  .bigText {
    text-align: center;
    font-size: 50px;
    height: 260px;
  }

  .actionButtonContainer {
    height: 160px;
  }

  .smallText {
    text-align: center;
    font-size: 18px;
  }
}
